import './global.js';

const urlParams = new URLSearchParams(window.location.search);
if (urlParams.get('from') === 'results') {
    $('.login-invoice').removeClass('hidden');
};

$('.toBtn.toLottery').on('click', () => {
    $('.login-invoice').removeClass('hidden');
});

$('.close-btn').on('click', () => {
    $('.login-invoice').addClass('hidden');
});

function nullValue() {
    let numArr = [
        $('.invoice-num input').eq(0).val(),
        $('.invoice-num input').eq(1).val(),
        $('.invoice-num input').eq(2).val()
    ];

    let dateArr = [
        $('.invoice-date input').eq(0).val(),
        $('.invoice-date input').eq(1).val(),
        $('.invoice-date input').eq(2).val()
    ];

    if (numArr.every(str => str === "") || dateArr.every(str => str === "")) {
        $('.err-text-1').removeClass('hidden');
        $('.err-text-1').text('請輸入發票號碼及日期');
        return false;
    } else {
        return true;
    }
}

function format() {
    // 要檢查的 value
    let inputValue = [];
    $('.invoice-num input').each((index, input) => {
        if ($(input).val() !== '') {
            inputValue.push($(input).val());
        }
    });

    // value 符不符合 format 規則
    let check = [];

    $(inputValue).each((index, value) => {
        // 每個 value 有三個項目要檢查 (length, 前兩碼英文, 後八碼數字)
        let valueCheck = [];

        if (value.split('').length !== 10) {
            $('.err-text-1').removeClass('hidden');
            $('.err-text-1').text('發票格式為英文字母2碼+數字8碼');
            valueCheck.push(false);
        }

        const letter = /^[A-Za-z]$/;
        if (!letter.test(value.split('')[0]) || !letter.test(value.split('')[1])) {
            $('.err-text-1').removeClass('hidden');
            $('.err-text-1').text('發票格式為英文字母2碼+數字8碼');
            valueCheck.push(false);
        };

        const num = /^[0-9]$/;
        for (let i = 2; i < value.split('').length; i++) {
            if (!num.test(value.split('')[i])) {
                $('.err-text-1').removeClass('hidden');
                $('.err-text-1').text('發票格式為英文字母2碼+數字8碼');
                valueCheck.push(false);
            };
        }

        if (valueCheck.every(OK => OK === true)) {
            check.push(true);
        } else {
            check.push(false);
        };
    });

    if (check.every(value => value === true)) {
        return true;
    } else {
        return false;
    }
}

function dateCheck() {
    let inputValue = [];
    $('.invoice-date input').each((index, input) => {
        if ($(input).val() !== '') {
            inputValue.push($(input).val());
        }
    });

    let check = [];
    $(inputValue).each((index, value) => {
        let input = new Date(value);
        let min = new Date('2024-06-17');
        let max = new Date('2024-08-31');
        if (input < min || input > max) {
            $('.err-text-1').removeClass('hidden');
            $('.err-text-1').text('僅限06/17-08/31內之發票');
            check.push(false);
        } else {
            check.push(true);
        }
    });

    if (check.every(value => value === true)) {
        return true;
    } else {
        return false;
    }
}

$('.next-step').on('click', () => {
    dateCheck();
    if (nullValue() && format() && dateCheck()) {
        $('.step1').addClass('hidden');
        $('.step2').removeClass('hidden');
    }
});

function idCheck() {
    // 有三個項目要檢查 (length, 第一碼英文, 後九碼數字)
    let check = [true];

    if ($('.id-num input').val().split('').length !== 10) {
        $('.err-text-2').removeClass('hidden');
        $('.err-text-2').text('身分證格式錯誤');
        check.push(false);
    }

    const letter = /^[A-Za-z]$/;
    if (!letter.test($('.id-num input').val().split('')[0])) {
        $('.err-text-2').removeClass('hidden');
        $('.err-text-2').text('身分證格式錯誤');
        check.push(false);
    };

    const num = /^[0-9]$/;
    for (let i = 1; i < $('.id-num input').val().split('').length; i++) {
        if (!num.test($('.id-num input').val().split('')[i])) {
            $('.err-text-2').removeClass('hidden');
            $('.err-text-2').text('身分證格式錯誤');
            check.push(false);
        };
    }

    if (check.every(OK => OK === true)) {
        return true;
    } else {
        return false;
    }
}

function nameCheck() {
    let check = [true];

    const name = /^[\u4e00-\u9fa5a-zA-Z\s]+$/;
    for (let i = 0; i < $('.invoice-data.name input').val().split('').length; i++) {
        if (!name.test($('.invoice-data.name input').val().split('')[i])) {
            $('.err-text-2').removeClass('hidden');
            $('.err-text-2').text('姓名僅接受中英文字元');
            check.push(false);
        };
    }

    if (check.every(OK => OK === true)) {
        return true;
    } else {
        return false;
    }
}

$('.submit-btn').on('click', () => {
    if ($('.invoice-data.name input').val() === '') {
        $('.err-text-2').removeClass('hidden');
        $('.err-text-2').text('請輸入姓名');
    } else {
        if (!nameCheck()) {
            $('.err-text-2').removeClass('hidden');
            $('.err-text-2').text('姓名僅接受中英文字元');
        } else {
            if ($('.invoice-data.id-num input').val() === '') {
                $('.err-text-2').removeClass('hidden');
                $('.err-text-2').text('請輸入身分證');
            } else {
                if (!idCheck()) {
                    $('.err-text-2').removeClass('hidden');
                    $('.err-text-2').text('身分證格式錯誤');
                } else {
                    if ($('.invoice-data.phone input').val() === '') {
                        $('.err-text-2').removeClass('hidden');
                        $('.err-text-2').text('請輸入聯絡電話');
                    } else {
                        if ($('.invoice-data.email input').val() === '') {
                            $('.err-text-2').removeClass('hidden');
                            $('.err-text-2').text('請輸入電子信箱');
                        } else {
                            if (!$('.invoice-data.checkbox input').is(':checked')) {
                                $('.err-text-2').removeClass('hidden');
                                $('.err-text-2').text('請詳閱活動辦法');
                            } else {
                                $('.step2').addClass('hidden');
                                $('.step3').removeClass('hidden');
                            }
                        }
                    }
                }
            }
        }
    }
});

$('.done-btn').on('click', () => {
    $('.login-invoice').addClass('hidden');
    $('.step1').removeClass('hidden');
    $('.invoice-data input').val('');
    $('.step3').addClass('hidden');
});